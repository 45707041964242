@use "./mixins.scss" as m;
$brand: #002175;
$txt-drkgry: #4f4f4f;
$txt-litgry: #aeaeae;
$erro: #f85656;
$dsbl-blu: #e0edff;
$drk-blue: #0066ff;
$sgreen: #00b89c;

.headtitle {
        @include m.text($size: 18px);

        &.bold {
                font-weight: 600 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh2 {
        @include m.text($size: 64px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh2-5 {
        @include m.text($size: 48px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh3-5 {
        @include m.text($size: 36px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh3 {
        @include m.text($size: 30px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh4 {
        @include m.text($size: 20px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh5 {
        @include m.text($size: 18px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.headh6 {
        @include m.text($size: 16px);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: #111724 !important;
        }
}

.titleM {
        @include m.text($size: 14px, $weight: 400);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: black !important;
        }
}

.titleN {
        @include m.text($size: 12px, $weight: 400);

        &.bold {
                font-weight: 600 !important;
        }

        &.semibold {
                font-weight: 500 !important;
        }

        &.dark {
                color: black !important;
        }

        &.lite {
                color: rgb(255, 255, 255) !important;
        }
}

.titleS {
        @include m.text($size: 10px, $weight: 400);

        &.semibold {
                font-weight: 500 !important;
        }

        &.bold {
                font-weight: 600 !important;
        }

        &.dark {
                color: black !important;
        }
}

.w-300 {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important;
}

.w-230 {
        width: 230px !important;
        min-width: 230px !important;
        max-width: 230px !important;
}

.w-210 {
        width: 210px !important;
        min-width: 210px !important;
        max-width: 210px !important;
}

.w-180 {
        width: 210px !important;
        min-width: 210px !important;
        max-width: 210px !important;
}

.w-160 {
        width: 160px !important;
        min-width: 160px !important;
        max-width: 160px !important;
}

.w-120 {
        width: 120px !important;
        min-width: 120px !important;
        max-width: 120px !important;
}

.w-140 {
        width: 140px !important;
        min-width: 140px !important;
        max-width: 140px !important;
}

.w-80 {
        width: 80px !important;
        min-width: 80px !important;
        max-width: 80px !important;
}

.w-60 {
        width: 60px !important;
        min-width: 60px !important;
        max-width: 60px !important;
}

.w-49 {
        width: 50px !important;
        min-width: 50px !important;
        max-width: 50px !important;
}

.w-40 {
        width: 40px !important;
        min-width: 40px !important;
        max-width: 40px !important;
}

.w-99 {
        width: 100px !important;
        min-width: 100px !important;
        max-width: 100px !important;
}

.w-fit {
        width: fit-content !important;
        min-width: fit-content !important;
        max-width: fit-content !important;
}

.height-5 {
        min-height: 5px !important;
        max-height: 5px !important;
        height: 5px;
}

.height-30 {
        min-height: 30px !important;
        max-height: 30px !important;
        height: 30px;
}

.height-36 {
        min-height: 36px !important;
        max-height: 36px !important;
        height: 36px;
}

.height-38 {
        min-height: 38px !important;
        max-height: 38px !important;
        height: 38px;
}

.height-40 {
        min-height: 40px !important;
        max-height: 40px !important;
        height: 40px;
}

.height-80 {
        min-height: 80px !important;
        max-height: 80px !important;
        height: 80px;
}

.height-50 {
        min-height: 50px !important;
        max-height: 50px !important;
        height: 50px;
}

.height-140 {
        min-height: 140px !important;
        max-height: 140px !important;
        height: 140px;
}

.height-54 {
        height: 54px !important;
        min-height: 54px !important;
        margin-bottom: 12px;
}

.height-m200 {
        max-height: 200px;
        overflow: auto;
}

.height-200 {
        height: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
        margin-bottom: 12px;
}

.height-45 {
        height: 45px !important;
        min-height: 45px !important;
        margin-bottom: 12px;
}

.height-fit {
        height: fit-content !important;
        min-height: fit-content !important;
        margin-bottom: 12px;
}

.avatar {
        border: 1px solid #c1c1c1;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        min-height: 34px;
        min-width: 34px;
        max-height: 34px;
        margin-left: 8px;
        max-width: 34px;
        object-fit: cover;

        &:not(.avatar.ms-0) {
                margin-left: 8px;
        }

        &.big {
                height: 60px;
                width: 60px;
                min-height: 60px;
                min-width: 60px;
                max-height: 60px;
        }

        &.size-28 {
                height: 28px;
                width: 28px;
                min-height: 28px;
                min-width: 28px;
                max-height: 28px;
        }



        &.dynamic {
                height: var(--avatarsize) !important;
                width: var(--avatarsize) !important;
                min-height: var(--avatarsize) !important;
                min-width: var(--avatarsize) !important;
                max-height: var(--avatarsize) !important;
        }
}

.initials {
        margin-left: 0 !important;
        display: grid;
        background: #ff6600;
        color: white;
        place-items: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
        max-height: 40px;
        max-width: 40px;

        &.has-border {
                outline: 1.5px solid #ffffff;
                outline-offset: -1px;
        }

        &.size-60 {
                height: 60px;
                width: 60px;
                min-height: 60px;
                min-width: 60px;
                max-height: 60px;
                max-width: 60px;
                font-size: 20px;
        }

        &.size-35 {
                height: 35px;
                width: 35px;
                min-height: 35px;
                min-width: 35px;
                max-height: 35px;
                max-width: 35px;

        }

        :root {
                --initialsize: 48px;
        }

        &.has-bord {
                border: 1px solid white;
        }

        &.dynamic {
                height: var(--initialsize);
                width: var(--initialsize);
                min-height: var(--initialsize);
                min-width: var(--initialsize);
                max-height: var(--initialsize);
                font-size: calc(var(--initialsize) / 2.7);
                line-height: 2.7;

                h6 {
                        font-size: calc(var(--initialsize) / 2.7);
                        line-height: 2.7;
                }
        }

        //     @mixin random-bgr() {
        //         background: rgb(random(255), random(255), random(255));
        //     }

        //     $initials: (
        //         A,
        //         B,
        //         C,
        //         D,
        //         E,
        //         F,
        //         G,
        //         H,
        //         I,
        //         J,
        //         K,
        //         L,
        //         M,
        //         N,
        //         O,
        //         P,
        //         Q,
        //         R,
        //         S,
        //         T,
        //         U,
        //         V,
        //         W,
        //         X,
        //         Y,
        //         Z
        //     );
        // $base-color: #00a6ff;
        // $spectrum: 270deg;
        // $offset: 180deg;

        // @each $initial in $initials {
        //     &.is-#{$initial} {
        //         @include random-bgr();
        //     }
        // }



        &.is-q,
        &.is-w,
        &.is-e,
        &.is-r {
                background-color: #7DBAFF !important;
        }

        &.is-k,
        &.is-y,
        &.is-u,
        &.is-s {
                background-color: #ffca1d !important;
        }

        &.is-o,
        &.is-p,
        &.is-a,
        &.is-i {
                background-color: #3B64AB !important;
        }

        &.is-d,
        &.is-l,
        &.is-g,
        &.is-h {
                background-color: #CA7AD1 !important;
        }

        &.is-j,
        &.is-t,
        &.is-f,
        &.is-z {
                background-color: #C4C4C4 !important;
        }

        &.is-x,
        &.is-c,
        &.is-v,
        &.is-m {
                background-color: rgb(32, 124, 178) !important;
        }

        &.is-n,
        &.is-b {
                background-color: rgb(255, 173, 86) !important;
        }

        h6 {
                margin: 0;
        }

        &.initial-1 {

                background-color: #4054b5 !important;

        }

        &.initial-2 {

                background-color: #b540ac !important;

        }

        &.initial-3 {

                background-color: darkcyan !important;

        }

        &.initial-4 {

                background-color: mediumorchid !important;

        }

        &.initial-5 {

                background-color: dodgerblue !important;

        }

        &.initial-6 {

                background-color: darkorange !important;

        }

        &.initial-7 {

                background-color: firebrick !important;

        }

        &.initial-8 {

                background-color: lightseagreen !important;

        }

        &.active {
                border: 1pg solid green !important;
        }
}

.gap-1 {
        gap: 4px;
}

.gap-2 {
        gap: 8px;
}

.gap-3 {
        gap: 12px;
}

.gap-4 {
        gap: 16px;
}

.gap-5 {
        gap: 20px;
}

.gap-6 {
        gap: 28px;
}

.c-pointer {
        cursor: pointer;
}

.lh-1 {
        line-height: 1 !important;
}

.lh-sm {
        line-height: 1.25 !important;
}

.lh-base {
        line-height: 1.5 !important;
}

.lh-lg {
        line-height: 2 !important;
}

.opacity-0 {
        opacity: 0;
}

.opacity-25 {
        opacity: 0.25;
}

.opacity-50 {
        opacity: 0.25;
}

.opacity-75 {
        opacity: 0.75;
}

.opacity-1 {
        opacity: 1;
}

.button {
        &.brand {
                @include m.button;
                @include m.btnreset;
                border: 1px solid $brand !important;

                &:disabled {
                        pointer-events: none;
                        @include m.button($back: $brand, $color: white);
                }

                &:hover {
                        color: var(--brand) !important;
                        background: #e8efff !important;
                }

                ion-icon.Vsmall {
                        font-size: 10px;
                }

                ion-icon.small-12 {
                        font-size: 12px;
                }

                ion-icon.small {
                        font-size: 16px;
                }

                ion-icon.medium {
                        font-size: 18px;
                }

                ion-icon.giant {
                        font-size: 20px;
                }

                ion-icon.verybig {
                        font-size: 24px;
                }
        }

        &.blue {
                @include m.button($back: white, $color: #0066ff, $bord: 1px solid #9ec5ff);
                @include m.btnreset;

                &:disabled {
                        pointer-events: none;
                }

                ion-icon.Vsmall {
                        font-size: 10px;
                }

                ion-icon.small {
                        font-size: 16px;
                }

                ion-icon.medium {
                        font-size: 18px;
                }

                ion-icon.giant {
                        font-size: 20px;
                }

                ion-icon.verybig {
                        font-size: 24px;
                }
        }

        &.blue-linear {
                @include m.button($back: white, $color: #ffffff, $bord: 1px solid #ffffff);
                @include m.btnreset;
                background: linear-gradient(180deg, #0066ff 42.19%, #3f99ff 75.61%, #80b1f3 100%) !important;

                &:disabled {
                        pointer-events: none;
                }

                ion-icon.Vsmall {
                        font-size: 10px;
                }

                ion-icon.small {
                        font-size: 16px;
                }

                ion-icon.medium {
                        font-size: 18px;
                }

                ion-icon.giant {
                        font-size: 20px;
                }

                ion-icon.verybig {
                        font-size: 24px;
                }
        }

        &.white {
                @include m.button($back: white, $color: #4f4f4f);

                &:hover {
                        background: transparentize(#E0EDFF, 0) !important;
                        color: #0066ff !important;
                        box-shadow: none !important;
                }

                &.active {
                        background: #9ec5ff !important;
                        box-shadow: inset 0px 0px 6px #0066ff !important;
                        border: none !important;
                        color: #0066ff !important;
                }

                &:disabled {
                        border: none !important;
                        pointer-events: none;
                        box-shadow: none !important;

                        &:not(.white.trans) {
                                background: #f1f1f1 !important;
                        }
                }

                ion-icon.Vsmall {
                        font-size: 10px;
                }

                ion-icon.Msmall {
                        font-size: 12px;
                }

                ion-icon.small {
                        font-size: 16px;
                }

                ion-icon.medium {
                        font-size: 18px;
                }

                ion-icon.giant {
                        font-size: 20px;
                }

                ion-icon.verybig {
                        font-size: 24px;
                }
        }

        &.white-blue {
                @include m.button($back: white, $color: #0066FF);

                &:hover {
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
                }

                &.active {
                        background: #9ec5ff !important;
                        border: 0.60037px solid #0066FF !important;
                }

                &:disabled {
                        background: #E0EDFF !important;
                        border: 0.60037px solid #E0EDFF !important;
                        color: #9EC5FF !important;

                        &:hover {
                                background: #E0EDFF !important;
                                border: 0.60037px solid #E0EDFF !important;
                                color: #9EC5FF !important;
                        }
                }

                ion-icon.Vsmall {
                        font-size: 10px;
                }

                ion-icon.Msmall {
                        font-size: 12px;
                }

                ion-icon.small {
                        font-size: 16px;
                }

                ion-icon.medium {
                        font-size: 18px;
                }

                ion-icon.giant {
                        font-size: 20px;
                }

                ion-icon.verybig {
                        font-size: 24px;
                }
        }

        &.no-shadow {
                box-shadow: none !important;
        }

        &.shadow {
                box-shadow: 0px 3px 10px rgba(148, 148, 148, 0.25) !important;
        }

        &.bord {
                border: 1px solid #c2c2c2 !important;

                &:hover {
                        border: 1px solid #0066ff !important;
                }
        }

        &.bord-white {
                border: 1px solid #fff !important;
                color: white;

                &:hover {
                        color: #0066ff !important;
                        border: 1px solid #9EC5FF !important;
                }
        }

        &.square {

                padding: 0 !important;
                width: 34px;
                height: 34px;
                min-width: 34px;
                min-width: 34px;
                max-height: 34px;
                max-height: 34px;
                font-size: 16px;

                &.size-24 {
                        border-radius: 7px !important;
                        width: 24px;
                        height: 24px;
                        min-width: 24px !important;
                        max-width: 24px !important;
                        max-height: 24px !important;
                        min-height: 24px !important;
                }

                ion-icon.small-12 {
                        font-size: 12px;
                }

                &.size-45 {
                        width: 45px;
                        height: 45px;
                        min-width: 45px !important;
                        max-width: 45px !important;
                        max-height: 45px !important;
                        min-height: 45px !important;
                }

                &.size-20 {
                        border-radius: 7px !important;
                        width: 20px;
                        height: 20px;
                        min-width: 20px !important;
                        max-width: 20px !important;
                        max-height: 20px !important;
                        min-height: 20px !important;
                }

                &.size-16 {
                        width: 16px;
                        height: 16px;
                        min-width: 16px !important;
                        max-width: 16px !important;
                        max-height: 16px !important;
                        min-height: 16px !important;
                }

                &.grid-center {
                        display: grid;
                        place-items: center;
                }

                &:disabled {
                        opacity: 0.5;

                }

                ion-icon.Vsmall {
                        font-size: 10px;
                }

                ion-icon.Msmall {
                        font-size: 12px;
                }

                ion-icon.small {
                        font-size: 16px;
                }

                ion-icon.medium {
                        font-size: 18px;
                }

                ion-icon.giant {
                        font-size: 20px;
                }

                ion-icon.verybig {
                        font-size: 24px;
                }
        }

        &.dark {
                background: transparentize($color: #ffffff, $amount: 0.9);
                border: 1px solid #c2c2c2 !important;
                color: white !important;

                &:hover {
                        background: white !important;
                        color: #4f4f4f !important;
                }
        }

        &.black {
                background: #000000 !important;

                &:not(.border-white) {
                        border: 1px solid #c2c2c2 !important;
                }

                color: white !important;

                &.active {
                        background: #0066FF !important;
                }
        }

        &.full {
                width: 100%;
        }

        &.trans {
                background: transparent !important;

                &:hover {
                        background: transparent !important;
                }
        }

        &.cust-color {
                @include m.button();

                --cust-color: #0066ff;

                &.filled {
                        border: 1px solid var(--cust-color) !important;
                        color: white;
                        background-color: var(--cust-color) !important;

                        &:disabled {
                                opacity: 0.5;
                                pointer-events: none !important;
                        }


                        &:hover {
                                box-shadow: 0px 0px 0px 3px hsla(var(--cust-color), 20%);
                        }
                }

                &.outlined {
                        border: 1px solid var(--cust-color) !important;
                        color: var(--cust-color) !important;
                        background: white;

                        &:disabled {
                                opacity: 0.5;
                                pointer-events: none !important;
                        }

                        &:hover {
                                color: white !important;
                                border: 1px solid var(--cust-color) !important;
                                background: var(--cust-color) !important;
                        }
                }
        }
}

.text-brand {
        color: #002175 !important;
}

.fs-1 {
        font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
        font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
        font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
        font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
        font-size: 1.25rem !important;
}

.fs-6 {
        font-size: 1rem !important;
}

.text-success {
        color: #00b06f !important;
}

.bg-brand {
        background: #00346b !important;
}