$brand: #002175;
$txt-drkgry: #3b434e;
$txt-litgry: #aeaeae;
$erro: #f85656;
$dsbl-blu: #e0edff;
$drk-blue: #0066ff;
$sgreen: #00b89c;

@mixin text($color: #3b434e, $size: 16px, $weight: 500px, $linehright: 1.2, $letterspace: 0.2px) {
        color: $color;
        font-size: $size !important;
        font-weight: $weight;
        line-height: $linehright;
        letter-spacing: $letterspace;
}

@mixin button($back: $brand,
        $bord: none,
        $color: white,
        $radi: 5px,
        $size: 12px,
        $weight: 500px,
        $width: fit-content,
        $height: 36px,
        $pad-l: 12px,
        $pad-r: 12px,
        $pad-t: 0px,
        $pad-b: 0px) {
        color: $color;
        font-size: $size;
        font-weight: $weight;
        padding: $pad-t $pad-r $pad-b $pad-l;
        background: $back;
        border-radius: $radi;
        height: $height;
        max-height: $height;
        min-height: $height;
        border: $bord !important;
        cursor: pointer;
        @include flex();
        @include btnreset();

        &:hover {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        &.active {
                background: transparentize($back, 0.9);
                border: 1px solid transparentize($back, 0);
        }

        &.orange {
                &:disabled {
                        background: transparentize($back, 0.6) !important;
                        border: 1px solid transparentize($back, 1) !important;
                        color: white !important;

                        &:hover {
                                background: transparentize($back, 0.6) !important;
                                border: 1px solid transparentize($back, 1) !important;
                                color: transparentize($back, 0.5) !important;
                                box-shadow: none;
                        }
                }
        }

        &:disabled {
                background: transparentize($back, 0.6);
                border: 1px solid transparentize($color, 0.6) !important;
                color: transparentize($color, 0.5);
                cursor: default;

                &:hover {
                        background: transparentize($color, 0.8) !important;
                        border: 1px solid transparentize($color, 0.6) !important;
                        color: transparentize($color, 0.5) !important;
                        box-shadow: none;
                }
        }

        ion-icon.big {
                min-height: 16px;
                min-width: 16px;
                max-height: 16px;
                max-width: 16px;
        }
}

@mixin btnreset($border: none) {
        border: $border;
}
@mixin flex($align: center, $justify: center, $wrap: nowrap, $direction: row, $gap: 8px) {
        display: flex;
        align-items: $align;
        justify-content: $justify;
        flex-wrap: $wrap;
        flex-direction: $direction;
        gap: $gap;
    }